/* eslint-disable indent */

import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../styles'
import { useUserLang } from '../../context/UserLangContext'
import generateOpensInANewTabTranslations from '../../data/generateOpensInANewTabTranslations'

const RichTextStyles = styled.div`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  text-transform: ${p => (p.font === 'smallCaps' ? 'uppercase' : '')};
  text-align: ${p => (p.center ? 'center' : 'left')};
  z-index: 1;

  * {
    /* Fallbacks */
    ${p =>
      p.font &&
      `
    font-size: ${p.theme.fonts.size[p.font]};
    font-family: ${p.theme.fonts.family[p.font]};
    font-weight: ${p.theme.fonts.weight[p.font]};
    line-height: ${p.theme.fonts.lineHeight[p.font]};
    letter-spacing: ${p.theme.fonts.letterSpacing[p.font]};
    `}
    color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
    text-transform: ${p =>
      p.font === 'smallCaps' || p.font === 'smallCapsSans' || p.font === 'largeCaps' ? 'uppercase' : 'inherit'};
    text-align: ${p => (p.center ? 'center' : 'left')};
    z-index: 1;

    a,
    a strong {
      color: ${theme.color.purple500};
    }
    strong {
      font-weight: 700;
    }
    ${p => p.richTextElementStyles && { ...p.richTextElementStyles }}
  }

  p {
    margin-bottom: 1em;
  }

  pre {
    font-family: ${theme.fonts.family.smallCaps};
    font-size: ${theme.fonts.size.smallBody};
  }

  a[target='_blank'],
  a[target='blank'] {
    ::after {
      content: '${p => p.opensInNewTabText || 'Opens in a new tab'}';
      position: absolute;
      left: -9000px;
      width: 0;
      overflow: hidden;
      clip: rect(0 0 0 0);
      white-space: nowrap;
      font-size: ${theme.fonts.size.tinyBody};
      text-align: center;
      padding: 4px;
      border-radius: 3px;
      background-color: ${theme.color.white};
    }
    &:focus,
    &:hover,
    &:active {
      ::after {
        top: 2em;
        left: 1em;
        width: auto;
        color: ${theme.color.offBlack};
        clip: unset;
        z-index: 9999;
        border: 1px solid ${theme.color.gray400};
        background-color: ${theme.color.white};
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }
`

const Text = styled.p`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  font-size: ${theme.fonts.size.body};
  font-family: ${theme.fonts.family.body};
  font-weight: ${theme.fonts.weight.body};
  line-height: ${theme.fonts.lineHeight.body};
  text-align: ${p => (p.center ? 'center' : 'left')};
  z-index: ${p => (p.zIndex ? p.zIndex : '1')};

  ${p =>
    p.bodyBold &&
    `
    font-size: ${theme.fonts.size.bodyBold};
    font-family: ${theme.fonts.family.bodyBold};
    font-weight: ${theme.fonts.weight.bodyBold};
    line-height: ${theme.fonts.lineHeight.bodyBold};
    `};
  ${p =>
    p.bodyMedium &&
    `
    font-size: ${theme.fonts.size.bodyMedium};
    font-family: ${theme.fonts.family.bodyMedium};
    font-weight: ${theme.fonts.weight.bodyMedium};
    line-height: ${theme.fonts.lineHeight.bodyMedium};
    `};
  ${p =>
    p.largeBody &&
    `
    font-size: ${theme.fonts.size.largeBody};
    font-family: ${theme.fonts.family.largeBody};
    font-weight: ${theme.fonts.weight.largeBody};
    line-height: ${theme.fonts.lineHeight.largeBody};
  `};
  ${p =>
    p.xLargeBodyBold &&
    `
    font-size: ${theme.fonts.size.xLargeBodyBold};
    font-family: ${theme.fonts.family.xLargeBodyBold};
    font-weight: ${theme.fonts.weight.xLargeBodyBold};
    line-height: ${theme.fonts.lineHeight.xLargeBodyBold};
  `};
  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p => p.strikeThrough && `text-decoration: line-through;`}
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
  * {
    color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
    font-size: ${theme.fonts.size.body};
    font-family: ${theme.fonts.family.body};
    font-weight: ${theme.fonts.weight.body};
    line-height: ${theme.fonts.lineHeight.body};
    ${p =>
      p.bodyBold &&
      `
    font-size: ${theme.fonts.size.bodyBold};
    font-family: ${theme.fonts.family.bodyBold};
    font-weight: ${theme.fonts.weight.bodyBold};
    line-height: ${theme.fonts.lineHeight.bodyBold};
    `};
    ${p =>
      p.bodyMedium &&
      `
    font-size: ${theme.fonts.size.bodyMedium};
    font-family: ${theme.fonts.family.bodyMedium};
    font-weight: ${theme.fonts.weight.bodyMedium};
    line-height: ${theme.fonts.lineHeight.bodyMedium};
    `};
    ${p =>
      p.largeBody &&
      `
    font-size: ${theme.fonts.size.largeBody};
    font-family: ${theme.fonts.family.largeBody};
    font-weight: ${theme.fonts.weight.largeBody};
    line-height: ${theme.fonts.lineHeight.largeBody};
  `};
    ${p => p.center && `text-align: center;`}
    ${p =>
      p.uppercase &&
      `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
    ${p =>
      p.underline &&
      `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
    ${p =>
      p.strikeThrough &&
      `
    text-decoration: line-through;
  `};
    ${p => p.onClick && `cursor: pointer;`}
    ${p =>
      p.numberOfLines &&
      `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
  a,
  a em,
  a strong,
  li a,
  a p {
      text-decoration: underline;
      text-underline-offset: 4px;
      color: ${theme.color.purple500};

      &:hover {
        color: ${theme.color.white};
      }
    }

    a[target='_blank'],
    a[target='blank'] {
      ::before {
        content: '${p => p.opensInNewTabText || 'Opens in a new tab'}';
        position: absolute;
        left: -9000px;
        width: 0;
        overflow: hidden;
        clip: rect(0 0 0 0);
        white-space: nowrap;
        font-size: ${theme.fonts.size.tinyBody};
        text-align: center;
        padding: 4px;
        border-radius: 3px;
        background-color: ${theme.color.white};
      }
      &:focus,
      &:hover,
      &:active {
        ::before {
          top: 2em;
          left: 1em;
          width: auto;
          color: ${theme.color.offBlack};
          clip: unset;
          z-index: 9999;
          border: 1px solid ${theme.color.gray400};
          background-color: ${theme.color.white};
          @media (max-width: 800px) {
            display: none;
          }
        }
      }
    }
  }

  a,
  a em,
  a strong,
  li a,
  a p {
    text-decoration: underline;
    text-underline-offset: 4px;
    color: ${theme.color.purple500};

    &:hover {
      color: ${theme.color.white};
    }
  }
`

const SmallText = styled.p`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  font-size: ${theme.fonts.size.smallBody};
  font-family: ${theme.fonts.family.smallBody};
  font-weight: ${theme.fonts.weight.smallBody};
  line-height: ${theme.fonts.lineHeight.smallBody};
  text-align: ${p => (p.center ? 'center' : 'left')};
  ${p =>
    p.smallBody &&
    `
    font-size: ${theme.fonts.size.smallBody};
    font-family: ${theme.fonts.family.smallBody};
    font-weight: ${theme.fonts.weight.smallBody};
    line-height: ${theme.fonts.lineHeight.smallBody};
  `};
  ${p =>
    p.smallBodyMedium &&
    `
    font-size: ${theme.fonts.size.smallBodyMedium};
    font-family: ${theme.fonts.family.smallBodyMedium};
    font-weight: ${theme.fonts.weight.smallBodyMedium};
    line-height: ${theme.fonts.lineHeight.smallBodyMedium};
  `};
  ${p =>
    p.smallBodyBold &&
    `
    font-size: ${theme.fonts.size.smallBodyBold};
    font-family: ${theme.fonts.family.smallBodyBold};
    font-weight: ${theme.fonts.weight.smallBodyBold};
    line-height: ${theme.fonts.lineHeight.smallBodyBold};
  `};
  ${p =>
    p.tinyBody &&
    `
    font-size: ${theme.fonts.size.tinyBody};
    font-family: ${theme.fonts.family.tinyBody};
    font-weight: ${theme.fonts.weight.tinyBody};
    line-height: ${theme.fonts.lineHeight.tinyBody};
    `};
  z-index: 1;
  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const SmallCaps = styled.p`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  font-size: ${theme.fonts.size.smallCaps};
  font-family: ${theme.fonts.family.smallCaps};
  font-weight: ${theme.fonts.weight.smallCaps};
  line-height: ${theme.fonts.lineHeight.smallCaps};
  letter-spacing: ${theme.fonts.letterSpacing.smallCaps};
  text-transform: uppercase;
  z-index: 1;
  text-align: ${p => (p.center ? 'center' : 'left')};

  ${p =>
    p.smallCapsSans &&
    `
    font-size: ${theme.fonts.size.smallCapsSans};
    font-family: ${theme.fonts.family.smallCapsSans};
    font-weight: ${p.medium ? '500' : theme.fonts.weight.smallCapsSans};
    line-height: ${theme.fonts.lineHeight.smallCapsSans};
    letter-spacing: ${theme.fonts.letterSpacing.smallCapsSans};
  `};
  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const LargeCaps = styled.p`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  font-size: ${theme.fonts.size.largeCaps};
  font-family: ${theme.fonts.family.largeCaps};
  font-weight: ${theme.fonts.weight.largeCaps};
  line-height: ${theme.fonts.lineHeight.largeCaps};
  letter-spacing: ${theme.fonts.letterSpacing.largeCaps};
  text-transform: uppercase;
  z-index: 1;
  text-align: ${p => (p.center ? 'center' : 'left')};

  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const LargeCapsInter = styled.p`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  font-size: ${theme.fonts.size.largeCapsInter};
  font-family: ${theme.fonts.family.largeCapsInter};
  font-weight: ${theme.fonts.weight.largeCapsInter};
  line-height: ${theme.fonts.lineHeight.largeCapsInter};
  letter-spacing: ${theme.fonts.letterSpacing.largeCapsInter};
  text-transform: uppercase;
  z-index: 1;
  text-align: ${p => (p.center ? 'center' : 'left')};

  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const Span = styled.span`
  font-size: ${p => (p.font ? theme.fonts.size[p.font] : theme.fonts.size.body)};
  font-family: ${p => (p.font ? theme.fonts.family[p.font] : theme.fonts.family.body)};
  font-weight: ${p => (p.font ? theme.fonts.weight[p.font] : theme.fonts.weight.body)};
  line-height: ${p => (p.font ? theme.fonts.lineHeight[p.font] : theme.fonts.lineHeight.body)};
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  z-index: 1;
  text-align: ${p => (p.center ? 'center' : 'left')};
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p => p.strikeThrough && `text-decoration: line-through;`}
`

const Heading1 = styled.h1`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  text-align: ${p => (p.center ? 'center' : 'left')};
  z-index: 1;
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const Heading2 = styled.h2`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  z-index: 1;
  text-align: ${p => (p.center ? 'center' : 'left')};
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const Heading3 = styled.h3`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  z-index: 1;
  text-align: ${p => (p.center ? 'center' : 'left')};
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
    `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const Heading4 = styled.h4`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  z-index: 1;
  text-align: ${p => (p.center ? 'center' : 'left')};
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const TextComponent = React.forwardRef((props, ref) => {
  const { userLang } = useUserLang()
  const languageData = generateOpensInANewTabTranslations({ userLang })

  let el = <Text {...props} ref={ref} />

  if (props.richText) {
    el = (
      <RichTextStyles
        {...props}
        ref={ref}
        className={`rich-text-container ${props.className ? props.className : ''}`}
        opensInNewTabText={languageData?.uppercase}
      />
    )
  }

  if (props.body) {
    el = <Text {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.bodyMedium) {
    el = <Text bodyMedium {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.bodyBold) {
    el = <Text bodyBold {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.largeBody) {
    el = <Text largeBody {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.smallBody) {
    el = <SmallText {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.smallBodyMedium) {
    el = <SmallText smallBodyMedium {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.smallBodyBold) {
    el = <SmallText smallBodyBold {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.tinyBody) {
    el = <SmallText tinyBody {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.largeCaps) {
    el = <LargeCaps {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.largeCapsInter) {
    el = <LargeCapsInter {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.smallCaps) {
    el = <SmallCaps {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.smallCapsSans) {
    el = <SmallCaps smallCapsSans {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.isSpan) {
    el = <Span {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.heading1) {
    el = <Heading1 role="heading" aria-level="1" {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.heading2) {
    el = <Heading2 role="heading" aria-level="2" {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.heading3) {
    el = <Heading3 role="heading" aria-level="3" {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  if (props.heading4) {
    el = <Heading4 role="heading" aria-level="4" {...props} ref={ref} opensInNewTabText={languageData?.uppercase} />
  }

  return el
})

TextComponent.displayName = 'TextComponent'

export default TextComponent
